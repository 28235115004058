import type { CarouselLoaderType } from 'components/03-organisms/MediaCarouselGate/types';

/* eslint-disable react/no-array-index-key */
export type SkeletonLoader =
  | CarouselLoaderType
  | ((props: UnknownProps) => JSX.Element);

export const generateSkeletonLoaders = (
  amountOfLoaders: number,
  Loader: SkeletonLoader
) => {
  const componentName = Loader.name;
  return new Array(amountOfLoaders)
    .fill(null)
    .map((_, index) => (
      <Loader key={`${componentName}-${index}`} index={index} />
    ));
};
