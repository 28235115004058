import { useQueries, useQuery } from '@tanstack/react-query';
import { ANONYMOUS_USER_ID } from 'helpers/constants';
import { shouldSearch } from 'helpers/utils/shouldSearch';
import { mixedContentMisoTypes } from 'helpers/utils/convertToSafeMisoType';
import MisoAdapter from 'adapters/MisoAdapter';

import type {
  MisoForWatchParams,
  PopularForWatchParams,
  MisoSearchParams,
  MisoSearchResponse,
  MisoSearchMixedContentResponse,
  MisoSearchShortsResponse
} from './types';
import {
  getLatestForWatch,
  getMisoForWatch,
  getMisoSearchMixedContent,
  getMisoSearchShorts,
  getPopularForWatch
} from './queryFunctions';

export const MISO_ELEMENTS_SIZE = 30;
const MISO_POPULAR_SIZE = 10;
export const MISO_SEARCH_SIZE = 20;
export const MISO_ELEMENTS_PAGE = 1;
export const POPULAR_TIME_PERIOD = 30;

export const PERSONALIZED_SHORTS_QUERY_KEY = 'personalizedShorts';
export const WATCH_NEXT_QUERY_KEY = 'watchNext';
export const POPULAR_FOR_WATCH_KEY = 'popularForWatch';
export const SEARCH_MIXED_CONTENT_QUERY_KEY = 'searchMixedContent';
export const SEARCH_SHORTS_QUERY_KEY = 'searchShorts';
export const LATEST_FOR_WATCH_KEY = 'latestsForWatch';

export const usePersonalizedShorts = (args: QueryParams) =>
  useQuery({
    enabled: !!args.uuid,
    queryFn: () =>
      getMisoForWatch({
        itemType: ['shorts'],
        page: MISO_ELEMENTS_PAGE,
        size: MISO_ELEMENTS_SIZE,
        ...args
      } as MisoForWatchParams),
    queryKey: [PERSONALIZED_SHORTS_QUERY_KEY, ...Object.values(args)],
    select: (data) => new MisoAdapter(data).adapt(),
    staleTime: 0
  });

export const useMisoForWatch = (args: QueryParams) =>
  useQuery({
    enabled: !!args.uuid,
    queryFn: () =>
      getMisoForWatch({
        page: MISO_ELEMENTS_PAGE,
        size: MISO_ELEMENTS_SIZE,
        ...args
      } as MisoForWatchParams),
    queryKey: [WATCH_NEXT_QUERY_KEY, ...Object.values(args)],
    select: (data) => new MisoAdapter(data).adapt()
  });

export const usePopularForWatch = (args: QueryParams) =>
  useQuery({
    queryFn: () =>
      getPopularForWatch({
        page: MISO_ELEMENTS_PAGE,
        size: MISO_POPULAR_SIZE,
        time_period: POPULAR_TIME_PERIOD,
        uuid: ANONYMOUS_USER_ID,
        ...args
      } as PopularForWatchParams),
    queryKey: [POPULAR_FOR_WATCH_KEY, ...Object.values(args)],
    select: (data) => new MisoAdapter(data).adapt()
  });

export const useLatestForWatch = (args: QueryParams) =>
  useQuery({
    queryFn: () =>
      getLatestForWatch({
        page: MISO_ELEMENTS_PAGE,
        size: MISO_POPULAR_SIZE,
        uuid: ANONYMOUS_USER_ID,
        ...args
      } as PopularForWatchParams),
    queryKey: [LATEST_FOR_WATCH_KEY, ...Object.values(args)],
    select: (data) => new MisoAdapter(data).adapt()
  });

const useMisoSearchMixedContent = (
  { q, type, uuid }: MisoSearchParams,
  initialData: MisoSearchMixedContentResponse
) => ({
  enabled:
    shouldSearch(q) && type.some((t) => mixedContentMisoTypes.includes(t)),
  gcTime: 0,
  initialData,
  queryFn: () => getMisoSearchMixedContent({ q, type, uuid }),
  queryKey: [SEARCH_MIXED_CONTENT_QUERY_KEY, q, type, uuid],
  staleTime: 0
});

const useMisoSearchShorts = (
  { q, type, uuid }: MisoSearchParams,
  initialData: MisoSearchShortsResponse
) => ({
  enabled: shouldSearch(q) && type.includes('shorts'),
  gcTime: 0,
  initialData,
  queryFn: () => getMisoSearchShorts({ q, uuid }),
  queryKey: [SEARCH_SHORTS_QUERY_KEY, q, type, uuid],
  staleTime: 0
});

export const useMisoSearch = (
  { q = '', type, uuid }: MisoSearchParams,
  initialData: MisoSearchResponse[]
) => {
  const mixedContentInitialData = initialData?.find(
    (data) => 'mixedContent' in data
  ) as MisoSearchMixedContentResponse;

  const shortsInitialData = initialData?.find(
    (data) => 'shorts' in data
  ) as MisoSearchShortsResponse;

  return useQueries({
    queries: [
      useMisoSearchMixedContent({ q, type, uuid }, mixedContentInitialData),
      useMisoSearchShorts({ q, type, uuid }, shortsInitialData)
    ]
  });
};
