import { useId } from 'react';
import ContentLoader from 'react-content-loader';

export const SubHeadingLoader = (props) => {
  const uniqueKey = useId();

  return (
    <ContentLoader
      animate={false}
      backgroundColor="#7A7A7A"
      foregroundColor="#7A7A7A"
      height="10"
      uniqueKey={uniqueKey || 'subheading-loader'}
      width="100%"
      {...props}
    >
      <rect height="10" rx="5" ry="5" width="50" x="0" y="0" />
    </ContentLoader>
  );
};
