import clsx from 'clsx';
import { TitleLoader } from 'components/01-atoms/Loader/TitleLoader';
import type { DesktopViewLimit } from 'components/03-organisms/Carousel';
import { useFluidElements } from 'helpers/hooks/useFluidElements';
import type { SkeletonLoader } from 'helpers/utils/generateSkeletonLoaders';
import { generateSkeletonLoaders } from 'helpers/utils/generateSkeletonLoaders';

type Props = {
  Loader: SkeletonLoader;
  desktopViewLimit?: DesktopViewLimit;
  withIndex?: boolean;
};

const CarouselLoader = ({ Loader, desktopViewLimit = 6, withIndex }: Props) => {
  const { carouselViewLimit } = useFluidElements(undefined, {
    desktopViewLimit,
    enabled: true
  });

  return (
    <div className="w-full">
      <div
        className={clsx('h-[30px] narrow-x:h-[37.5px]', {
          'mb-3 narrow-x:mb-7': withIndex,
          'mb-7': !withIndex
        })}
      >
        <TitleLoader />
      </div>
      <div
        className={clsx(
          'flex gap-x-4 overflow-hidden narrow-x:w-full narrow-x:grid narrow-x:gap-x-6',
          {
            'narrow-x:grid-cols-4 wide-x:grid-cols-fluid-[300px]':
              desktopViewLimit === 4,
            'narrow-x:grid-cols-6 wide-x:grid-cols-fluid-[196px]':
              desktopViewLimit === 6
          }
        )}
      >
        {generateSkeletonLoaders(carouselViewLimit, (props) => (
          <div className="w-full">
            <Loader withIndex={withIndex} {...props} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarouselLoader;
