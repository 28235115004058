import { useId } from 'react';
import ContentLoader from 'react-content-loader';

export const HeadingLoader = (props) => {
  const uniqueKey = useId();

  return (
    <ContentLoader
      animate={false}
      backgroundColor="#7A7A7A"
      className="w-full narrow-x:w-[150px]"
      foregroundColor="#7A7A7A"
      height="16"
      uniqueKey={uniqueKey || 'heading-loader'}
      {...props}
    >
      <rect
        className="w-full narrow-x:w-[150px]"
        height="16"
        rx="8"
        ry="8"
        x="0"
        y="0"
      />
    </ContentLoader>
  );
};
