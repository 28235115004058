/* eslint-disable react-hooks/exhaustive-deps */
import type { UseQueryResult } from '@tanstack/react-query';
import CarouselLoader from 'components/02-molecules/CarouselLoader';
import { useEffect } from 'react';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import { MediaPosterLoader } from 'components/02-molecules/Loader/MediaPosterLoader';
import { MediaThumbnailLoader } from 'components/02-molecules/Loader/MediaThumbnailLoader';

import type { Props as MediaCarouselProps } from '../MediaCarousel';
import MediaCarousel from '../MediaCarousel';
import type { MediaCarouselType } from '../MediaCarousel/types';

import type { CarouselLoaderType } from './types';

const carouselLoaderMap: Record<MediaCarouselType, CarouselLoaderType> = {
  continueWatching: MediaThumbnailLoader,
  poster: MediaPosterLoader,
  short: MediaThumbnailLoader,
  thumbnailPreview: MediaThumbnailLoader,
  thumbnailSimple: MediaThumbnailLoader
};

type Props = Omit<MediaCarouselProps, 'items'> & {
  onError?: VoidFunction;
  onSuccess?: (data: MediaItem[]) => void;
  queryProps?: QueryParams;
  useQuery: (args: QueryParams) => UseQueryResult<MediaItem[]>;
};

const MediaCarouselGate = ({
  desktopViewLimit,
  isFluid,
  onError,
  onSuccess,
  queryProps,
  type,
  useQuery,
  withIndex,
  ...mediaCarouselProps
}: Props) => {
  const { data, isError, isLoading, isSuccess } = useQuery(queryProps);

  useEffect(() => {
    if (isSuccess) {
      onSuccess?.(data);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      onError?.();
    }
  }, [isError]);

  if (isError) return null;

  if (isLoading || !data) {
    const Loader = carouselLoaderMap[type];

    return (
      <CarouselLoader
        desktopViewLimit={desktopViewLimit}
        Loader={Loader}
        withIndex={withIndex}
      />
    );
  }

  return (
    <MediaCarousel
      {...mediaCarouselProps}
      desktopViewLimit={desktopViewLimit}
      isFluid={isFluid}
      items={data}
      type={type}
      withIndex={withIndex}
    />
  );
};

export default MediaCarouselGate;
