import { client } from 'lib/graphqlRequest';
import type {
  QueryLatestForWatchArgs,
  QueryMisoForWatchArgs,
  QueryMisoSearchArgs,
  QueryMisoSimilarArgs,
  QueryMisoTrendingArgs,
  QueryPopularForWatchArgs
} from 'helpers/types/jwplayer-subgraph';

import {
  GET_MISO_TRENDING,
  GET_MISO_SIMILAR,
  GET_MISO_PERSONALIZED_DATA,
  GET_WATCH_POPULAR,
  GET_MISO_SEARCH_MIXED_CONTENT,
  GET_MISO_SEARCH_SHORTS,
  GET_MISO_LATEST_FOR_WATCH
} from './graphql';
import type {
  MisoSimilarResponse,
  MisoTrendingResponse,
  MisoForWatchParams,
  MisoForWatchResponse,
  MisoSearchParams,
  MisoSearchShortsResponse,
  MisoSearchMixedContentResponse,
  MisoTrendingParams,
  MisoSimilarParams,
  PopularForWatchResponse,
  PopularForWatchParams
} from './types';

const SIZE = 20;
const TYPE = ['video'];

export const getMisoTrending = ({ uuid }: MisoTrendingParams) =>
  client.request<MisoTrendingResponse, QueryMisoTrendingArgs>(
    GET_MISO_TRENDING,
    {
      size: SIZE,
      type: TYPE,
      uuid
    }
  );

export const getMisoSimilar = ({ similarTo, uuid }: MisoSimilarParams) =>
  client.request<MisoSimilarResponse, QueryMisoSimilarArgs>(GET_MISO_SIMILAR, {
    similarTo,
    size: SIZE,
    type: TYPE,
    uuid
  });

export const getMisoForWatch = (params: MisoForWatchParams, cookie?: string) =>
  client.request<MisoForWatchResponse, QueryMisoForWatchArgs>(
    GET_MISO_PERSONALIZED_DATA,
    params,
    {
      Cookie: cookie
    }
  );

export const getPopularForWatch = (
  params: PopularForWatchParams,
  cookie?: string
) =>
  client.request<PopularForWatchResponse, QueryPopularForWatchArgs>(
    GET_WATCH_POPULAR,
    params,
    {
      Cookie: cookie
    }
  );

export const getLatestForWatch = (
  params: MisoForWatchParams,
  cookie?: string
) =>
  client.request<MisoForWatchResponse, QueryLatestForWatchArgs>(
    GET_MISO_LATEST_FOR_WATCH,
    params,
    {
      Cookie: cookie
    }
  );

export const getMisoSearchMixedContent = (
  { q, type, uuid }: MisoSearchParams,
  cookie?: string
) =>
  client.request<MisoSearchMixedContentResponse, QueryMisoSearchArgs>(
    GET_MISO_SEARCH_MIXED_CONTENT,
    {
      q,
      /* we need to filter out potential "shorts" type, shorts are going in the separate query called getMisoSearchShorts */
      type: type.filter((t) => t !== 'shorts'),
      uuid
    },
    {
      Cookie: cookie
    }
  );

export const getMisoSearchShorts = (
  { q, uuid }: Omit<MisoSearchParams, 'type'>,
  cookie?: string
) =>
  client.request<MisoSearchShortsResponse, QueryMisoSearchArgs>(
    GET_MISO_SEARCH_SHORTS,
    {
      q,
      type: ['shorts'],
      uuid
    },
    {
      Cookie: cookie
    }
  );
