import type { MisoForWatchResponse } from 'apis/miso/types';

/*
  currently MisoForWatch queries are broken by returning null values
  temporary solution is to ask for bigger list (30)
  and then manually limit the list to desired size (20)
*/

const MISO_FOR_WATCH_LIMIT = 20;

class MisoAdapter {
  private value: MisoForWatchResponse;

  limit: number;

  constructor(
    rawData: MisoForWatchResponse,
    limit: number = MISO_FOR_WATCH_LIMIT
  ) {
    this.value = rawData;
    this.limit = limit;
  }

  adapt() {
    return this.value?.misoForWatch
      ?.map(({ mediaItem }) => mediaItem)
      .filter(Boolean)
      .slice(0, this.limit);
  }
}

export default MisoAdapter;
