import clsx from 'clsx';
import Flex from 'components/01-atoms/Flex';
import { HeadingLoader } from 'components/01-atoms/Loader/HeadingLoader';
import { ImageLoader } from 'components/01-atoms/Loader/ImageLoader';
import { SubHeadingLoader } from 'components/01-atoms/Loader/SubHeadingLoader';
import CarouselIndex from 'components/03-organisms/Carousel/CarouselIndex';

type Props = {
  index?: number;
  withIndex?: boolean;
};

export const MediaPosterLoader = ({ index, withIndex }: Props) => (
  <Flex
    className={clsx('flex-col gap-3 narrow-x:gap-4 relative', {
      'mt-4 narrow-x:mt-0': withIndex
    })}
    role="alert"
  >
    {withIndex && <CarouselIndex index={index + 1} />}
    <div
      className={clsx('w-full aspect-h-4 aspect-w-3', {
        '-mt-4': withIndex
      })}
    >
      <ImageLoader />
    </div>
    <HeadingLoader backgroundColor="#4E4E4E" foregroundColor="#4E4E4E" />
    <SubHeadingLoader />
  </Flex>
);
