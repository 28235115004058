import { useId } from 'react';
import ContentLoader from 'react-content-loader';

export const ImageLoader = (props) => {
  const uniqueKey = useId();

  return (
    <ContentLoader
      animate={false}
      backgroundColor="#4E4E4E"
      foregroundColor="#4E4E4E"
      height="100%"
      uniqueKey={uniqueKey || 'image-loader'}
      width="100%"
      {...props}
    >
      <rect height="100%" rx="4" ry="4" width="100%" x="0" y="0" />
    </ContentLoader>
  );
};
