import { POPULAR_TIME_PERIOD, usePopularForWatch } from 'apis/miso/queries';
import MediaCarouselGate from 'components/03-organisms/MediaCarouselGate';
import { ANONYMOUS_USER_ID } from 'helpers/constants';
import type { ImageProps } from 'next/image';

type Props = {
  category?: string;
  priority?: ImageProps['priority'];
};

const Top10Shorts = ({ category, priority }: Props) => (
  <MediaCarouselGate
    desktopViewLimit={4}
    headingResponsiveVariant="medium"
    headingText="Top 10 Shorts"
    priority={priority}
    queryProps={{
      ...(category ? { category } : {}),
      itemType: ['shorts'],
      page: 1,
      size: 10,
      time_period: POPULAR_TIME_PERIOD,
      uuid: ANONYMOUS_USER_ID
    }}
    type="short"
    useQuery={usePopularForWatch}
    withIndex
  />
);

export default Top10Shorts;
