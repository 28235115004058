import { MEDIA_ITEM_FRAGMENT } from 'apis/fragments';
import { gql } from 'graphql-request';

export const GET_MISO_TRENDING = gql`
  query MisoTrending($uuid: String, $page: Int, $size: Int, $type: [String]) {
    misoTrending(uuid: $uuid, page: $page, size: $size, type: $type) {
      mediaItem {
        ...MediaItemFragment
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;

export const GET_MISO_SIMILAR = gql`
  query MisoSimilar(
    $similarTo: String
    $uuid: String
    $type: [String]
    $size: Int
  ) {
    misoSimilar(similarTo: $similarTo, uuid: $uuid, type: $type, size: $size) {
      mediaItem {
        ...MediaItemFragment
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;

export const GET_MISO_PERSONALIZED_DATA = gql`
  query getMisoForWatch(
    $uuid: String
    $page: Int
    $size: Int
    $itemType: [String]
    $category: String
    $subcategory: String
  ) {
    misoForWatch(
      uuid: $uuid
      page: $page
      size: $size
      item_type: $itemType
      category: $category
      subcategory: $subcategory
    ) {
      mediaItem {
        ...MediaItemFragment
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;

export const GET_WATCH_POPULAR = gql`
  query getPopularForWatch(
    $uuid: String
    $page: Int
    $size: Int
    $itemType: [String]
    $time_period: Int
    $category: String
  ) {
    misoForWatch: popularForWatch(
      uuid: $uuid
      page: $page
      size: $size
      item_type: $itemType
      time_period: $time_period
      category: $category
    ) {
      mediaItem {
        ...MediaItemFragment
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;

export const GET_MISO_LATEST_FOR_WATCH = gql`
  query getLatestForWatch(
    $uuid: String
    $page: Int
    $size: Int
    $itemType: [String]
    $category: String
    $subcategory: String
  ) {
    misoForWatch: latestForWatch(
      uuid: $uuid
      page: $page
      size: $size
      item_type: $itemType
      category: $category
      subcategory: $subcategory
    ) {
      mediaItem {
        ...MediaItemFragment
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;

export const GET_MISO_SEARCH_MIXED_CONTENT = gql`
  query MisoSearchMixedContent($q: String, $type: [String]) {
    mixedContent: misoSearch(q: $q, type: $type, size: 20) {
      mediaItem {
        ...MediaItemFragment
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;

export const GET_MISO_SEARCH_SHORTS = gql`
  query MisoSearchShorts($q: String, $type: [String]) {
    shorts: misoSearch(q: $q, type: $type, size: 20) {
      mediaItem {
        ...MediaItemFragment
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;
