import { useMisoForWatch } from 'apis/miso/queries';
import MediaCarouselGate from 'components/03-organisms/MediaCarouselGate';
import { ANONYMOUS_USER_ID } from 'helpers/constants';
import { useUserType } from 'helpers/hooks/useUserType';
import type { ImageProps } from 'next/image';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';

type Props = {
  category?: string;
  containerClasses?: string;
  onError?: VoidFunction;
  onSuccess?: (data: MediaItem[]) => void;
  priority?: ImageProps['priority'];
  subcategory?: string;
};

const RecommendedFilmsAndSeries = ({
  category,
  containerClasses,
  onError,
  onSuccess,
  priority,
  subcategory
}: Props) => {
  const { uuid } = useUserType();

  return (
    <MediaCarouselGate
      containerClasses={containerClasses}
      headingLevel={3}
      headingResponsiveVariant="small"
      headingText="Films & Series"
      onError={onError}
      onSuccess={onSuccess}
      priority={priority}
      queryProps={{
        ...(category ? { category } : {}),
        ...(subcategory ? { subcategory } : {}),
        itemType: ['films', 'series'],
        uuid: uuid || ANONYMOUS_USER_ID
      }}
      type="poster"
      useQuery={useMisoForWatch}
    />
  );
};

export default RecommendedFilmsAndSeries;
