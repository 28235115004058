import Flex from 'components/01-atoms/Flex';
import { HeadingLoader } from 'components/01-atoms/Loader/HeadingLoader';
import { SubHeadingLoader } from 'components/01-atoms/Loader/SubHeadingLoader';
import { ImageLoader } from 'components/01-atoms/Loader/ImageLoader';
import CarouselIndex from 'components/03-organisms/Carousel/CarouselIndex';

type Props = {
  index?: number;
  showSubheading?: boolean;
  withIndex?: boolean;
};

export const MediaThumbnailLoader = ({
  index,
  showSubheading,
  withIndex
}: Props) => (
  <div className="grid grid-cols-1 gap-y-4" role="alert">
    <div className="z-1">
      {withIndex && <CarouselIndex index={index + 1} />}
      <div className="row-span-2 aspect-h-9 aspect-w-16">
        <ImageLoader />
      </div>
    </div>
    <Flex className="flex-col gap-y-3">
      <HeadingLoader />
      {showSubheading && (
        <SubHeadingLoader backgroundColor="#4E4E4E" foregroundColor="#4E4E4E" />
      )}
    </Flex>
  </div>
);
