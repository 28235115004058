import { usePopularForWatch } from 'apis/miso/queries';
import MediaCarouselGate from 'components/03-organisms/MediaCarouselGate';
import { ANONYMOUS_USER_ID } from 'helpers/constants';
import { useUserType } from 'helpers/hooks/useUserType';
import type { ImageProps } from 'next/image';

type Props = {
  category?: string;
  priority?: ImageProps['priority'];
};

const Top10FilmsAndSeries = ({ category, priority }: Props) => {
  const { uuid } = useUserType();

  return (
    <MediaCarouselGate
      headingResponsiveVariant="medium"
      headingText="Top 10 Films & Series"
      priority={priority}
      queryProps={{
        ...(category ? { category } : {}),
        itemType: ['films', 'series'],
        uuid: uuid || ANONYMOUS_USER_ID
      }}
      type="poster"
      useQuery={usePopularForWatch}
      withIndex
    />
  );
};

export default Top10FilmsAndSeries;
