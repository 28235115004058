import { useId } from 'react';
import ContentLoader from 'react-content-loader';

export const TitleLoader = (props) => {
  const uniqueKey = useId();

  return (
    <ContentLoader
      animate={false}
      backgroundColor="#7A7A7A"
      foregroundColor="#7A7A7A"
      height={24}
      uniqueKey={uniqueKey || 'title-loader'}
      width={160}
      {...props}
    >
      <rect height={24} rx="12" ry="12" width={160} x="0" y="0" />
    </ContentLoader>
  );
};
