import type { MisoItemType } from 'apis/miso/types';

export const availableMisoTypes: MisoItemType[] = ['films', 'series', 'shorts'];
export const mixedContentMisoTypes: MisoItemType[] = ['films', 'series'];

export const convertToSafeMisoType = (
  queryType: MisoItemType | MisoItemType[]
) => {
  if (!queryType) return null;
  const type = Array.isArray(queryType) ? queryType[0] : queryType;
  if (availableMisoTypes.includes(type)) return type;
  return null;
};
